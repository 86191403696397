<template>
  <div v-if="!loading" id="app">
    <div v-if="$store.getters.user === null">
      <Login />
    </div>
    <div v-else>
      <Header :user="$store.getters.user" @onLogout="setUserNull" />
      <router-view :user="$store.getters.user" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/Header.vue";
import Login from "@/components/Login.vue";
import { clearUserTokens } from "@/utils/requests";
import router from "@/router";
import store from "@/utils/store";

@Component({
  router,
  store,
  components: {
    Login,
    Header,
  },
})
export default class App extends Vue {
  loading = true;

  async created() {
    this.loading = true;
    await this.$store.getters.asyncUser;
    this.loading = false;
  }

  setUserNull() {
    clearUserTokens();
    this.$router.go(0);
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
