var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_vm.$store.getters.user === null ? _c('div', [_c('Login')], 1) : _c('div', [_c('Header', {
    attrs: {
      "user": _vm.$store.getters.user
    },
    on: {
      "onLogout": _vm.setUserNull
    }
  }), _c('router-view', {
    attrs: {
      "user": _vm.$store.getters.user
    }
  })], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }