var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('img', {
    attrs: {
      "id": "st-logo",
      "src": require("../assets/SchmalzTechLogo.png")
    }
  }), _c('div', {
    staticClass: "column is-6 is-offset-3 is-one-fifth-desktop is-offset-two-fifths-desktop"
  }, [_c('form', {
    staticClass: "box",
    attrs: {
      "action": ""
    }
  }, [_c('b-field', {
    attrs: {
      "label-for": "username",
      "label": "Username",
      "label-position": "on-border"
    }
  }, [_c('b-input', {
    attrs: {
      "id": "username"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.submitLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  })], 1), _c('b-field', {
    attrs: {
      "label-for": "password",
      "label": "Password",
      "label-position": "on-border"
    }
  }, [_c('b-input', {
    attrs: {
      "id": "password",
      "type": "password"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.submitLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('div', {
    staticClass: "buttons is-centered"
  }, [_c('b-button', {
    attrs: {
      "type": "is-primary"
    },
    on: {
      "click": _vm.submitLogin
    }
  }, [_vm._v(" Login ")])], 1)], 1), _vm.loginFailed ? _c('h2', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("Incorrect Login")]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }