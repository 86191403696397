import Vue from "vue";
import Buefy from "buefy";
import VueApexCharts from "vue-apexcharts";
import "./style/st-theme.scss";
import App from "./App.vue";
import "@/utils/requests";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Buefy);
Vue.config.productionTip = false;

Vue.use(VueApexCharts);
Vue.component("ApexChart", VueApexCharts);

new App().$mount("#app");
