<template>
  <div class="login" style="text-align: center">
    <img id="st-logo" src="../assets/SchmalzTechLogo.png" />
    <div class="column is-6 is-offset-3 is-one-fifth-desktop is-offset-two-fifths-desktop">
      <form action="" class="box">
        <b-field label-for="username" label="Username" label-position="on-border">
          <!-- eslint-disable-next-line vue/no-deprecated-v-on-native-modifier -->
          <b-input id="username" v-model="username" @keyup.enter.native="submitLogin" />
        </b-field>
        <b-field label-for="password" label="Password" label-position="on-border">
          <!-- eslint-disable-next-line vue/no-deprecated-v-on-native-modifier -->
          <b-input id="password" v-model="password" type="password" @keyup.enter.native="submitLogin" />
        </b-field>
        <div class="buttons is-centered">
          <b-button type="is-primary" @click="submitLogin"> Login </b-button>
        </div>
      </form>
      <h2 v-if="loginFailed" style="color: red">Incorrect Login</h2>
    </div>
  </div>
</template>

<script lang="ts">
import { loginUser } from "@/utils/requests";
import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class Login extends Vue {
  username = "";
  password = "";
  loginFailed = false;

  async submitLogin() {
    try {
      await loginUser(this.username, this.password);
      this.loginFailed = false;
      await this.$router.go(0);
    } catch {
      this.loginFailed = true;
    }
  }
}
</script>

<style scoped>
#st-logo {
  width: 300px;
  margin-top: 100px;
  margin-bottom: 20px;
}
</style>
