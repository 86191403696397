<template>
  <div class="header">
    <b-navbar fixed-top>
      <template #brand>
        <b-navbar-item tag="router-link" to="/">
          <img id="st-gear" src="../assets/SchmalzTechGear.png" />
        </b-navbar-item>
        <b-navbar-item tag="router-link" to="/orders"> Orders </b-navbar-item>
        <b-navbar-item v-if="user.is_superuser" tag="router-link" to="/summary" :user="user"> Summary </b-navbar-item>
        <b-navbar-item tag="router-link" to="/inventory"> Inventory </b-navbar-item>
        <b-navbar-item tag="router-link" to="/utilities"> Utilities </b-navbar-item>
        <b-navbar-item v-if="user.is_superuser" tag="router-link" to="/custom-shipping" :user="user">
          Custom Shipping
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item>
          <b-button type="is-primary" @click="submitLogout"> Logout </b-button>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { User } from "@/types/users";

@Component
export default class Header extends Vue {
  @Prop() user!: User;

  submitLogout() {
    this.$emit("onLogout");
  }
}
</script>

<style>
#st-gear {
  height: 150px;
}

.header {
  margin-bottom: 40px;
}
</style>
