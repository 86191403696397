import Vuex from "vuex";
import { User } from "@/types/users";
import Vue from "vue";
import { isLoggedIn } from "@/utils/requests";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    authState: {
      complete: false,
      user: null as User | null,
    },
  },
  mutations: {
    setUser(state, { user, loggedOut = false }: { user: User | null; loggedOut: boolean }) {
      state.authState.user = user;
      state.authState.complete = !loggedOut;
    },
  },
  actions: {
    async loadUser({ commit }) {
      commit("setUser", { user: await isLoggedIn() });
    },
  },
  getters: {
    user: (state): User | null => {
      return state.authState.user;
    },
    asyncUser: (state): Promise<User | null> => {
      if (state.authState.complete) {
        return Promise.resolve(state.authState.user);
      } else {
        return store.dispatch("loadUser").then(() => Promise.resolve(store.getters.user));
      }
    },
  },
});

export default store;
