import Vue from "vue";
import "vue-router";
import VueRouter from "vue-router";

import store from "@/utils/store";
import { User } from "@/types/users";

const CustomShipping = () => import("@/views/CustomShipping.vue");
const Home = () => import("@/views/Home.vue");
const Inventory = () => import("@/views/Inventory.vue");
const NotFound = () => import("@/views/NotFound.vue");
const OAuthReturn = () => import("@/views/OAuthReturn.vue");
const Orders = () => import("@/views/Orders.vue");
const Summary = () => import("@/views/Summary.vue");
const Utilities = () => import("@/views/Utilities.vue");

/************************************************************************/

declare module "vue-router" {
  interface RouteMeta {
    requiresSuperuser?: boolean;
    allowUnauthed?: boolean;
  }
}

/************************************************************************/

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
    meta: {
      allowUnauthed: true,
    },
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory,
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
  },
  {
    path: "/orders/:id(\\d+)",
    name: "Order Detail",
    component: Orders,
  },
  {
    path: "/summary",
    name: "Summary",
    component: Summary,
    meta: {
      requiresSuperuser: true,
    },
  },
  {
    path: "/custom-shipping",
    name: "Custom Shipping",
    component: CustomShipping,
    meta: {
      requiresSuperuser: true,
    },
  },
  {
    path: "/custom-shipping/:id",
    name: "Custom Shipping Detail",
    component: CustomShipping,
    meta: {
      requiresSuperuser: true,
    },
  },
  {
    path: "/ebay/oauth/:status",
    name: "OAuth Return",
    component: OAuthReturn,
  },
  {
    path: "/digikey/oauth",
    name: "OAuth Return",
    component: OAuthReturn,
  },
  {
    path: "/utilities",
    name: "Utilities",
    component: Utilities,
    props: true,
  },
  {
    path: "/utilities/:utility",
    name: "Utilities",
    component: Utilities,
    props: true,
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
    meta: {
      allowUnauthed: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta?.allowUnauthed) {
    return next();
  }
  store.getters.asyncUser.then((user: User | null) => {
    if (user === null || (to.meta?.requiresSuperuser && !user?.is_superuser)) {
      return next("/");
    } else {
      return next();
    }
  });
});

export default router;
