import router from "@/router";
import axios, { AxiosResponse } from "axios";
import { oauthURL } from "./oauth";
import { User } from "@/types/users";
import store from "@/utils/store";

const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
export const BASE_URL = process.env.VUE_APP_API_URL;

export const api = axios.create({
  baseURL: BASE_URL,
});

const setAccess = (accessToken: string) => window.localStorage.setItem(ACCESS_TOKEN, accessToken);
const setRefresh = (refreshToken: string) => window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
const getAccess = () => window.localStorage.getItem(ACCESS_TOKEN);
const getRefresh = () => window.localStorage.getItem(REFRESH_TOKEN);
const clearAccess = () => window.localStorage.removeItem(ACCESS_TOKEN);
const clearRefresh = () => window.localStorage.removeItem(REFRESH_TOKEN);

const setDefaultHeader = () => {
  const token = getAccess();
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};
setDefaultHeader();

const isExpired = (token: string) => {
  const parsed = JSON.parse(window.atob(token.split(".")[1]));
  const expiry = parsed.exp * 1000;
  return Date.now() >= expiry;
};

export const isLoggedIn = async (): Promise<User | null> => {
  const accessToken = getAccess();
  if (accessToken) {
    if (!isExpired(accessToken)) {
      return await retrieveUser();
    } else if (getRefresh()) {
      try {
        await refreshToken();
        return await retrieveUser();
      } catch (e) {
        return null;
      }
    }
  }
  return null;
};

const retrieveUser = async (): Promise<User | null> => {
  try {
    const response = await api.get<User>(`/users/current/`);
    return response.data;
  } catch {
    return null;
  }
};

type LoginResponse = {
  access: string;
  refresh: string;
};

export const loginUser = async (username: string, password: string): Promise<AxiosResponse<LoginResponse>> => {
  const payload = { username, password };
  const response = await api.post<LoginResponse>(`/token/`, payload);
  setAccess(response.data.access);
  setRefresh(response.data.refresh);
  setDefaultHeader();
  return response;
};

export const clearUserTokens = (): void => {
  clearAccess();
  clearRefresh();
  api.defaults.headers.common["Authorization"] = undefined;
  store.commit("setUser", { user: null, loggedOut: true });
};

const refreshToken = async () => {
  const refreshBody = { refresh: getRefresh() };
  try {
    const response = await api.post<Pick<LoginResponse, "access">>(`/token/refresh/`, refreshBody);
    setAccess(response.data.access);
    setDefaultHeader();
    return response;
  } catch (error) {
    clearUserTokens();
    throw error;
  }
};

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 && getRefresh()) {
      if (error.response.config.url === `/token/refresh/`) {
        clearUserTokens();
        return Promise.reject(error);
      }

      return refreshToken().then(() => {
        error.config.headers["Authorization"] = `Bearer ${getAccess()}`;
        error.config.baseURL = undefined;
        return api.request(error.config);
      });
    } else if (error.response.status === 403) {
      let marketplace;
      switch (error.response.data.error_code) {
        case "EBAY_AUTH_ERROR":
          marketplace = "ebay" as const;
          break;
        case "DIGIKEY_AUTH_ERROR":
          marketplace = "digikey" as const;
      }
      if (marketplace) {
        const url = oauthURL(marketplace, router.currentRoute.fullPath);
        return api
          .get<{ redirect_url: string }>(url)
          .then((response) => window.location.replace(response.data.redirect_url));
      }
    }
    return Promise.reject(error);
  },
);
