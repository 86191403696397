var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('b-navbar', {
    attrs: {
      "fixed-top": ""
    },
    scopedSlots: _vm._u([{
      key: "brand",
      fn: function () {
        return [_c('b-navbar-item', {
          attrs: {
            "tag": "router-link",
            "to": "/"
          }
        }, [_c('img', {
          attrs: {
            "id": "st-gear",
            "src": require("../assets/SchmalzTechGear.png")
          }
        })]), _c('b-navbar-item', {
          attrs: {
            "tag": "router-link",
            "to": "/orders"
          }
        }, [_vm._v(" Orders ")]), _vm.user.is_superuser ? _c('b-navbar-item', {
          attrs: {
            "tag": "router-link",
            "to": "/summary",
            "user": _vm.user
          }
        }, [_vm._v(" Summary ")]) : _vm._e(), _c('b-navbar-item', {
          attrs: {
            "tag": "router-link",
            "to": "/inventory"
          }
        }, [_vm._v(" Inventory ")]), _c('b-navbar-item', {
          attrs: {
            "tag": "router-link",
            "to": "/utilities"
          }
        }, [_vm._v(" Utilities ")]), _vm.user.is_superuser ? _c('b-navbar-item', {
          attrs: {
            "tag": "router-link",
            "to": "/custom-shipping",
            "user": _vm.user
          }
        }, [_vm._v(" Custom Shipping ")]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "end",
      fn: function () {
        return [_c('b-navbar-item', [_c('b-button', {
          attrs: {
            "type": "is-primary"
          },
          on: {
            "click": _vm.submitLogout
          }
        }, [_vm._v(" Logout ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }